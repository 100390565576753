@font-face {
  font-family: 'NotoSansThai';
  src: url(./dist/fonts/NotoSansThai-Regular.ttf);
}

@font-face {
  font-family: 'Prompt';
  src: url(./dist/fonts/Prompt-Regular.ttf);
}

@font-face {
  font-family: 'Sarabun';
  src: url(./dist/fonts/Sarabun-Regular.ttf);
}

@font-face {
  font-family: 'Manrope';
  src: url(./dist/fonts/Manrope-Regular.ttf);
}

body {
  margin: 0;
  font-family: 'Manrope', 'Sarabun', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  -webkit-transition: background .3s;
  transition: background .3s;
}

::-webkit-scrollbar-thumb {
  background: #7690a9;
  border: 4px solid transparent;
  border-radius: 8px;
}