.common-page-group {
    &.profile-page {
        .content-wrapper {
            height: 100%;
            border-radius: 0.5rem;

            .ant-card-head {
                border-radius: 0.5rem 0.5rem 0 0;
                background: @blue;
                color: @gray;
            }

            .ant-card-body {
                border-radius: 0 0 0.5rem 0.5rem;
                border: 1px solid @blue;
            }
        }

        .user-action-wrapper {
            height: 100%;
            border-radius: 0.5rem;

            .ant-card {
                height: auto;
            }

            .ant-card-head {
                border-radius: 0.5rem 0.5rem 0 0;
                background: @gold;
                color: @gray;
            }

            .ant-btn-primary {
                background-color: @gold;
                border-color: @gold;
            }

            .ant-card-body {
                border-radius: 0 0 0.5rem 0.5rem;
                border: 1px solid @gold;
            }

        }

        .user-rate-wrapper {
            height: 100%;
            border-radius: 0.5rem;

            .ant-card-head {
                border-radius: 0.5rem 0.5rem 0 0;
                background: @green;
                color: @gray;
            }

            .ant-card-body {
                border-radius: 0 0 0.5rem 0.5rem;
                border: 1px solid @green;
            }
        }

        .ant-card {
            height: 100%;
            border-radius: 0.5rem;
        }
    }



    &.report-reward-page {
        .page-content {
            min-height: 100vh;

            .search-section {
                min-height: 2rem;
                margin-bottom: 1rem;

                .search {
                    .ant-input-group-wrapper {
                        width: auto;

                        .ant-input-affix-wrapper {
                            border-radius: 0.5rem 0 0 0.5rem;
                        }

                        .ant-input-group-addon>.ant-btn {
                            border-radius: 0 0.5rem 0.5rem 0;
                        }
                    }
                }
            }

            .table-wrapper {
                margin-bottom: 1rem;
            }
        }

    }

    .card-based-page {
        .page-content {
            max-width: 50vw;
            background: unset;
            box-shadow: unset;

            // small-device
            @media (max-width: 768px) {
                max-width: 100vw;
            }

            .ant-card {
                border-radius: 0.5rem;
                .shadow1-mixin();

                .ant-card-body {
                    min-height: 15rem;
                }
            }

            .card-actions {
                .ant-card-body {
                    min-height: auto;
                }
            }

            .card-items-centered {
                .ant-card-body {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .content-wrapper {
                font-size: 2rem;
            }
        }
    }

    &.closed-numbers-page {
        .card-based-page();

        .market-image {
            width: 100%;
            max-width: 5rem;
        }
    }

    &.howtoplay-page {
        width: fit-content;
        min-width: 70rem;

        .content-image {
            max-width: 50rem;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

    }


}