.bet-page {
    @gold: #faad14;
    @gold-soft: #ffe5b0;
    @yellow: #ffec3d;
    @yellow-soft: #ffffb8;
    @blue: #1890ff;
    @blue1: #e6f7ff;
    @blue10: #002766;
    @green: #52c41a;
    @green-soft: #d9fcc7;
    @red: #f5222d;
    @black: #414756;
    @gray: #A5ABBD;
    @pink: #eb2f96;

    .page-content-wrapper {
        .page-content {
            min-height: 100vh;
        }
    }

    // if mobile
    // @media (max-width: 768px) {
    //     .page-content-wrapper {
    //         padding: 0;

    //         .page-content {
    //             padding: 0;
    //             background: unset;
    //             box-shadow: unset;


    //             .betNumber-area {
    //                 min-height: 10rem;

    //                 .number-wrapper {
    //                     margin-right: 0.5rem;
    //                 }
    //             }

    //             .ant-input-number-group-wrapper {
    //                 width: 100%;
    //             }

    //             .play-inputs-row {
    //                 justify-content: center;
    //             }
    //         }
    //     }
    // }


    .text-title {
        font-size: 1.2rem;
    }

    .play-wrapper {
        border: 1px solid transparent;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        border-radius: 0.25rem;
        padding: 1rem;
        background-color: #e7f0ff;
        min-height: 55vh;
    }

    .btn-wrapper {
        margin-bottom: 1rem;
    }

    .tabs-playmode.ant-tabs {
        width: 100%;

        .ant-tabs-nav-list {
            // width: 100%;

            .ant-tabs-tab+.ant-tabs-tab {
                margin-left: 14px;
            }

            .ant-tabs-tab {
                font-size: 1rem;
                border: 0;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

                .ant-tabs-tab-btn {
                    // color: #a5abbd;
                }
            }

            .ant-tabs-tab-active {
                background: @blue;

                .ant-tabs-tab-btn {
                    color: @blue1;
                }
            }
        }


    }



    .ant-image {
        vertical-align: middle;
    }

    .playmode-btn {
        color: @blue  !important;
        border-color: @blue  !important;

        &:hover,
        &:active {
            color: @blue10  !important;
            border-color: @blue10  !important;
        }
    }

    .removed-betNumber {
        background-color: white !important;
        color: @gray  !important;
        text-decoration: line-through;
    }

    .betNumber-area {
        min-height: 5rem;
        margin-bottom: 1rem;
        padding: 0 0.5rem;

        .number-wrapper {
            color: white;
            background-color: @blue10;
            margin-right: 1rem;
            font-size: 1.2rem;
            cursor: default !important;
            border-radius: 0.25rem;
        }
    }

    .mode6-number {
        background-color: #fff3a9;
        border-collapse: collapse;
        border: 1px solid #faad14;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 1.25rem;
        .font-default();
        cursor: pointer;

        &.mode6-selected {
            background-color: #faad14;
            color: white;
        }
    }

    .bill-area-detail {
        text-align: center;

        div:nth-child(1),
        div:nth-child(3) {
            color: @blue;
            font-weight: bold;
        }

        div:nth-child(2) {
            color: @red;
            font-weight: bold;
        }
    }

    .bill-area-betNumber {
        padding: 0.5rem 1rem;
        background: white;
        color: black;
        border-radius: 0.25rem;
    }

    .bill-area-btn {
        margin: auto;
        text-align: center;
    }

    .table-section {
        td {
            font-size: 0.8rem;
            padding: 4px 4px;
        }

        .table-wrapper {
            margin-bottom: 1rem;
        }

        .ant-table-title {
            background-color: @blue;
            color: #fff;
            border-radius: 0.5rem 0.5rem 0 0;
        }

        .ant-table-cell {
            .ant-table {
                margin: 0 !important;
            }

            .ant-table-title {
                background-color: @blue1;
                color: @black;
                border-radius: 0.5rem 0.5rem 0 0;
            }
        }
    }

    .ant-spin {
        min-height: 55vh;
    }

    .closetime {
        .ant-statistic-content {
            font-size: 14px;
        }
    }

    .closetime {
        position: absolute;
        top: 0;
        right: 0;
    }

    // @media (min-width: 1600px) {
    //     .closetime {
    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //     }
    // }

    // @media (max-width: 1599px) {
    //     .closetime {
    //         position: relative;
    //     }
    // }
}