@blue: #1890ff;
@white: #fff;
@gray : #f0f0f0;
@secondary: #8c8c8c;
@yellow: #fadb14;
@black: #262626;
@siderColor : #001528;
@siderSecondary : #0d263b;
@green : #28a745;
@red: #f5222d;
@gold: #faad14;

@import './bet.less';
@import './member.less';
@import './agent.less';
@import './admin.less';
@import './common.less';



.color-primary {
    color: @primary-color
}

.display-none {
    display: none
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}


.shadow3-mixin {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.shadow2-mixin {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.shadow1-mixin {
    box-shadow: rgb(100 100 111 / 10%) 1px 2px 3px 0px;
}

.shadow0-mixin {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.bg-yellow {
    background-color: #fff488;
}

// text color
.text-black {
    color: @black;
}

.text-gray {
    color: @secondary;
}

.text-gold {
    color: @gold;
}

.text-yellow {
    color: @yellow;
}

.text-blue {
    color: @blue;
}

.text-red {
    color: @red;
}

.text-green {
    color: @green;
}

.text-secondary {
    color: @secondary;
    font-size: 0.8rem;
}

.text-small {
    font-size: 0.8rem;
}

.text-big {
    font-size: 1.2rem;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.font-default {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}



.font-sarabun {
    font-family: 'Sarabun', sans-serif;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

input {
    .font-default()
}


.page-title {
    .title {
        font-size: 1.25rem;
        font-weight: bold;
    }
}

.text-bold {
    font-weight: bold;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.my-1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.my-2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.my-3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.my-4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mx-1 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.mx-2 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.mx-3 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.mx-4 {
    margin-left: 2rem;
    margin-right: 2rem;
}

.mx-5 {
    margin-left: 3rem;
    margin-right: 3rem;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}


.page-content-wrapper {
    // font-family: 'Manrope', "helvetica neue", Arial, sans-serif;
    padding: 1rem;
    min-height: 85vh;

    .page-content {
        .shadowed-box();

        .search {
            .ant-input-group-wrapper {
                width: auto;
            }
        }
    }
}

.shadowed-box {
    padding: 1rem;
    background: white;
    border-radius: 0.5rem;
    min-height: 50vh;
    .shadow3-mixin();
}

.custom-header {
    padding-left: 1rem;
}

.custom-footer {
    color: #eff2f5;

    .footer-logo {
        max-width: 15vw;

        @media only screen and (min-width: 800px) and (max-width: 1200px) {
            max-width: 30vw;
        }

        @media only screen and (max-width: 800px) {
            max-width: 50vw;
        }
    }
}

.sider-wrapper {
    .user-info {
        padding: 1rem;
    }

    .ant-menu-item-divider {
        background-color: #0d263b;
    }
}

.page-404 {
    display: flex;
    justify-content: center;
    flex-flow: column;
    height: 100%;
}

.breadcrumb-styled {
    padding: 1rem;
    background: @siderColor;
    border-radius: 0.5rem;
    margin: 0.5rem;

    .ant-breadcrumb {
        color: @white;

        li:last-child {
            color: rgba(255, 255, 255, 0.75)
        }

        .ant-breadcrumb-separator {
            color: rgba(255, 255, 255, 0.45)
        }

    }



}

.current-rate-component {
    color: #fff;
    padding: 1rem;

    .ant-table-wrapper {
        margin-top: 1rem;

        .ant-table {
            color: white;
            font-size: 12px;
            background: none;

            .ant-table-container>.ant-table-content>table {
                border: unset;
            }
        }

        .ant-table-tbody>tr:hover>td {
            background: unset;
        }

    }

}

.f-right {
    float: right;
}

.opacity-50 {
    opacity: 0.5;
}

.header-wrapper {
    min-height: 2rem;
    margin-bottom: 1rem;

    .search {
        .ant-input-group-wrapper {
            width: auto;

            .ant-input-affix-wrapper {
                border-radius: 0.5rem 0 0 0.5rem;
            }

            .ant-input-group-addon>.ant-btn {
                border-radius: 0 0.5rem 0.5rem 0;
            }
        }
    }
}

.highlighted-won-order {
    .text-bold();
}

.market-image-in-td {
    width: 100%;
    max-width: 3rem;
}

.ant-table-content {
    @borderColor : #bfbfbf;

    .col-first-group-cell {
        border-left: 2px solid @borderColor  !important;
    }

    .col-group-bordered {
        border-left: 2px solid @borderColor  !important;
    }

}