.member-page-group {
    &.home-member-page {
        .market-wrapper {
            .market-title {
                font-size: 1.2rem;
                font-weight: bold;
                margin-bottom: 0.5rem;
            }

            .market-content {
                .market-item {
                    .ant-row {
                        height: 100%;

                        .col-flex-vertical-center {
                            display: flex;
                            align-items: center;
                        }

                    }


                    .market-image-wrapper {
                        padding-right: 0.5rem;

                        .market-image {
                            width: 100%;
                        }
                    }


                    height: 100%;
                    background: @gray;
                    border-radius: 0.25rem;
                    padding: 0.5rem;
                    .shadow0-mixin();

                    .name,
                    .date {
                        font-size: 1rem;
                        font-weight: bold;
                    }

                    .ant-statistic-content {
                        font-size: 14px;
                    }



                    &.market-active {
                        color: @gray;
                        background: @green;

                        .ant-statistic-content {
                            color: #f0f0f0;
                        }
                    }

                    &.market-willActive {
                        color: @black;
                        background: @gold;
                        opacity: 0.5;

                        .ant-statistic-content {
                            color: @black;
                        }
                    }

                    &.market-inactive {
                        color: @black;
                    }
                }
            }

            margin-bottom: 1rem;
        }
    }

    &.orders-page {
        .page-content {
            min-height: 100vh;

        }

    }

    &.orders-by-type-page {
        .page-content {
            min-height: 100vh;

            .table-wrapper {
                margin-bottom: 1rem;
            }
        }

    }


    &.transactions-page {
        .page-content {
            min-height: 100vh;

            .table-wrapper {
                margin-bottom: 1rem;
            }
        }

    }


    &.transactions-report-page {
        .page-content {
            min-height: 100vh;

            .table-wrapper {
                margin-bottom: 1rem;
            }

            .checkboxGroup {
                .group-wrapper {
                    margin-bottom: 1rem;
                }
            }
        }

    }

    &.member-wait-report-page {
        .page-content {
            min-height: 100vh;

            .table-wrapper {
                margin-bottom: 1rem;
            }
        }

    }

    .displayInfo-section {
        margin-bottom: 1rem;
    }

    .search-section {
        min-height: 2rem;
        margin-bottom: 1rem;

        .search {
            .ant-input-group-wrapper {
                width: auto;

                .ant-input-affix-wrapper {
                    border-radius: 0.5rem 0 0 0.5rem;
                }

                .ant-input-group-addon>.ant-btn {
                    border-radius: 0 0.5rem 0.5rem 0;
                }
            }
        }
    }
}