.login-wrapper {
    min-height: 100vh;
    padding-left: 0;
    padding-right: 0;
}

.login-container {
    position: relative;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    padding: 0px 5% 5%;
    align-items: center !important;
    background: rgb(0, 165, 199);
    background: linear-gradient(90deg, rgba(0, 165, 199, 1) 0%, rgba(44, 58, 158, 1) 100%);
    overflow-x: hidden;
    display: flex;
    justify-content: center;
}

.login-logo {
    font-size: 5vw !important;
    margin-bottom: 0.5rem !important;
    font-weight: bolder !important;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */

    @media only screen and (max-width: 767px) {
        font-size: 15vw !important;
    }

    @media only screen and (min-width: 1201px) and (max-width: 1600px) {
        font-size: 10vw !important;
    }
}

.logo {
    font-size: 1rem !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0.5em;
    text-align: center;
}

.login-footer {
    color: rgb(200, 200, 200);
    position: relative;
    top: 10rem;
}
@text-color: rgba(0, 0, 0, 1);@heading-color: rgba(0, 0, 0, 1);