.agent-page-group {
    @lightblue: #e5f3ff;

    .search-section {
        .ant-card {
            background-color: @lightblue;
            .shadow1-mixin();
        }

        min-height: 2rem;
        margin-bottom: 1rem;

        .search {
            .ant-input-group-wrapper {
                width: auto;

                .ant-input-affix-wrapper {
                    border-radius: 0.5rem 0 0 0.5rem;
                }

                .ant-input-group-addon>.ant-btn {
                    border-radius: 0 0.5rem 0.5rem 0;
                }
            }
        }
    }

    &.my-setting-page {
        .page-content {
            min-height: 100vh;
        }
    }

    &.bet-result-orders-page {
        .ant-table-summary {
            background-color: @lightblue;
        }
    }

    &.bet-result-page {
        .ant-table-summary {
            background-color: @lightblue;
        }
    }

    &.bet-result-by-date-page {
        .table-wrapper {
            margin-bottom: 1rem;
        }

        .checkboxGroup {
            .group-wrapper {
                margin-bottom: 1rem;
            }
        }

        .ant-table-summary {
            background-color: @lightblue;
        }


        .displayInfo-section {
            margin-bottom: 1rem;
        }
    }

    &.landing-page {
        .page-content {
            max-width: 50vw;
            background: unset;
            box-shadow: unset;

            // small-device
            @media (max-width: 768px) {
                max-width: 100vw;
            }

            .ant-card {
                border-radius: 0.5rem;
                .shadow1-mixin();
            }

            .content-wrapper {
                font-size: 2rem;
            }
        }
    }

    .row-locked-user {
        background-color: #ffd0d0;
    }

    .ant-table-tbody>tr.row-locked-user:hover>td {
        background: unset;
    }
}