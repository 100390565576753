@import '~antd/dist/antd.less';

@import './dist/css/main.less';

@primary-color: #1890ff;

.App {
  .ant-layout.content-layout {
    overflow-y: auto;
    overflow-x: hidden;
    // margin-left: 80px;
  }

  @media only screen and (min-width: 768px) {
    .ant-layout.content-layout {
      overflow-x: auto;
      // margin-left: 256px;

      &.sider-is-collapsed {
        // margin-left: 80px;
      }
    }
  }

  .ant-table-thead th {
    text-align: center !important;
  }

  .ant-layout {
    transition: all 100ms;
  }

  .ant-layout-content {
    min-height: 100vh;
    padding: 1rem;
  }



  .ant-layout-header {
    background-color: #fff;
    padding: 0;
    margin: 0;
  }

  .ant-layout-footer {
    background-color: @primary-color;
    margin: 0;
  }

  .ant-layout-sider {
    .ant-menu-item {
      padding-left: 24px;
    }

    .ant-menu {
      .ant-menu-title-content {
        .anticon {
          padding-right: 8px;
        }
      }
    }
  }




}

.ant-modal-root {
  .lotto-param {
    padding: 0.5rem 1rem;
    outline: 1px solid #d0d0d0;

    &:hover,
    &.lotto-param-selected {
      outline: 1px solid #1890ff;
      color: #1890ff;
    }
  }

  .lotto-param-radius {
    border-radius: 0.25rem;
  }


}

.responsive-modal {
  &.modal-min-height {
    .ant-modal-body {
      min-height: 50vh;
    }
  }

  width: 100vw !important;
  // //at large device
  // @media only screen and (min-width: 1200px) {
  //   width: 70vw !important;
  // }

  // //at medium device
  // @media only screen and (min-width: 768px) and (max-width : 1199px) {
  //   width: 80vw !important;
  // }

  // //at small device
  // @media only screen and (max-width: 768px) {
  //   width: 100vw !important;
  // }
}
@text-color: rgba(0, 0, 0, 1);@heading-color: rgba(0, 0, 0, 1);