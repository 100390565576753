.admin-page-group {
    @lightblue: #e5f3ff;

    .search-section {
        .ant-card {
            background-color: @lightblue;
            .shadow1-mixin();
        }

        min-height: 2rem;
        margin-bottom: 1rem;

        .search {
            .ant-input-group-wrapper {
                width: auto;

                .ant-input-affix-wrapper {
                    border-radius: 0.5rem 0 0 0.5rem;
                }

                .ant-input-group-addon>.ant-btn {
                    border-radius: 0 0.5rem 0.5rem 0;
                }
            }
        }
    }

    .page-content {
        min-height: 100vh;
    }

    .ant-table-summary {
        background-color: @lightblue;
    }


    &.home-admin-page {
        .market-wrapper {
            .market-title {
                font-size: 1.2rem;
                font-weight: bold;
                margin-bottom: 0.5rem;
            }

            .market-content {
                .market-item {
                    transition: .4s;
                    border: 1px solid transparent;

                    &:hover {
                        // background: #fafafa !important;
                        color: #000000 !important;
                        border: 1px solid @blue;
                    }

                    .ant-row {
                        height: 100%;

                        .col-flex-vertical-center {
                            display: flex;
                            align-items: center;
                        }

                    }


                    .market-image-wrapper {
                        padding-right: 0.5rem;

                        .market-image {
                            width: 100%;
                        }
                    }


                    height: 100%;
                    background: @gray;
                    border-radius: 0.25rem;
                    padding: 0.5rem;
                    .shadow0-mixin();

                    .name,
                    .date {
                        font-size: 1rem;
                        font-weight: bold;
                    }

                    .ant-statistic-content {
                        font-size: 14px;
                    }




                    &.market-pending {
                        color: #000000;
                        background: #ffe49e;
                    }

                    &.market-late {
                        color: #6e0000;
                        background: #ffb2b6;
                    }

                    &.market-willActive,
                    &.market.canceled,
                    &.market.inactive {
                        color: @black;

                    }

                    &.market-active {
                        background: #dceeff;

                    }
                }
            }

            margin-bottom: 1rem;
        }
    }

    &.result-add-page {
        .market-wrapper {
            .ant-card-body {
                padding: 0;
            }
        }

        .market-item {
            padding: 1rem;
            transition: .4s;

            .ant-row {
                height: 100%;

                .col-flex-vertical-center {
                    display: flex;
                    align-items: center;
                }
            }

            .market-image-wrapper {
                padding-right: 0.5rem;

                .market-image {
                    width: 100%;
                    max-width: 5rem;
                }
            }

            height: 100%;

            .name,
            .date {
                font-size: 1rem;
                font-weight: bold;
            }

            .ant-statistic-content {
                font-size: 14px;
            }

            &.market-pending {
                color: #000000;
                // background: #ffe49e;
            }

            &.market-late {
                color: #6e0000;
                // background: #ffb2b6;
            }

            &.market-willActive,
            &.market.canceled,
            &.market.inactive {
                color: @black;
            }

            &.market-active {
                // background: #84ff87;
            }
        }

        .page-content {
            min-width: 50vw;
            background: unset;
            box-shadow: unset;


            // small-device
            @media (max-width: 768px) {
                max-width: 100vw;
            }

            .ant-card {
                border-radius: 0.5rem;
                .shadow1-mixin();

                .ant-card-body {
                    min-height: 15rem;
                }
            }

            .card-actions {
                .ant-card-body {
                    min-height: auto;
                }
            }

            .card-items-centered {
                .ant-card-body {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }



            .content-wrapper {
                font-size: 2rem;
            }
        }
    }
}